import { createTheme, responsiveFontSizes } from '@mui/material/styles';

// font-family: Raleway, sans-serif;
// font-family: Roboto Condensed, sans-serif;

// Colour Palette
// 678840 light green
// 323C1A dark green
// C57200 orange
// 482714 brown
// 998f76 tan

const palette = {
  primary: { main: '#998f76' },
  secondary: { main: '#C57200' },
  tertiary: { light: '#424F24', main: '#323C1A', dark: '#202711', contrastText: '#fff' },
  error: { main: '#A61111' },
  warning: { main: '#FF7000' },
  info: { main: '#00AAFF' },
  success: { main: '#099100' },
  header: { main: '#323C1A' },
  background: { default: '#DDD6C5' },
};

const constants = {
  typography: {
    h1: {
      fontFamily: 'Raleway, sans-serif',
      color: palette.tertiary.main,
    },
    h2: {
      fontFamily: 'Raleway, sans-serif',
      color: palette.tertiary.main,
    },
    h3: {
      fontFamily: 'Raleway, sans-serif',
      color: palette.tertiary.main,
    },
    h4: {
      fontFamily: 'Raleway, sans-serif',
      color: palette.tertiary.main,
    },
    h5: {
      fontFamily: 'Raleway, sans-serif',
      color: palette.tertiary.main,
    },
    h6: {
      fontFamily: 'Raleway, sans-serif',
      color: palette.tertiary.main,
    },
    fontFamily: 'Roboto Condensed, sans-serif',
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        message: {
          width: '100%',
        },
        action: {
          alignItems: 'center',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: ({ ownerState, _ }) => ({
          ...(ownerState.hover === 'true' && {
            '&:hover': {
              cursor: 'pointer',
            },
          }),
        }),
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          minHeight: 65,
          backgroundColor: palette.tertiary.main,
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          padding: 0,
          minWidth: 60,
          minHeight: 65,
          color: palette.primary.main,
          '&.Mui-selected': {
            color: palette.secondary.main,
          },
          '& .MuiSvgIcon-root': {
            width: '1.5rem',
            height: '1.5rem',
          },
        },
        label: {
          fontSize: '0.8rem',
          '&.Mui-selected': {
            fontSize: '0.9rem',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
        variant: 'contained',
        color: 'secondary',
      },
    },
    MuiCard: {
      defaultProps: {
        sx: {
          boxShadow: 1,
        },
      },
      styleOverrides: {
        root: ({ ownerState, _ }) => ({
          ...(ownerState.hover === 'true' && {
            '&:hover': {
              cursor: 'pointer',
            },
          }),
        }),
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          paddingTop: 0,
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          color: 'tertiary.main',
        },
      },
      styleOverrides: {
        action: ({ ownerState, _ }) => ({
          ...(ownerState.center_action === 'true' && {
            alignSelf: 'center',
          }),
        }),
      },
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: false,
      },
    },
    MuiDivider: {
      defaultProps: {
        variant: 'fullWidth',
      },
    },
    MuiGrid: {
      defaultProps: {
        justifyContent: 'center',
        alignItems: 'center',
      },
      styleOverrides: {
        root: {
          listStyleType: 'none',
          padding: 0,
        },
      },
    },
    MuiImageList: {
      styleOverrides: {
        root: {
          overflowY: 'visible',
        },
      },
    },
    MuiImageListItem: {
      styleOverrides: {
        root: ({ ownerState, _ }) => ({
          ...(ownerState.hover && {
            '&:hover': {
              cursor: 'pointer',
            },
          }),
        }),
        img: {
          objectFit: 'contain',
        },
      },
    },
    MuiImageListItemBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#000000CC',
        },
        title: {
          color: palette.primary.main,
          fontSize: '1.3rem',
          fontWeight: 500,
          textAlign: 'right',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          '&:hover': {
            cursor: 'pointer',
          },
        },
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          padding: '0.25rem 2rem 0.25rem 2rem',
          borderRadius: 25,
          margin: '0',
        },
      },
    },
    MuiModal: {
      defaultProps: {
        closeAfterTransition: true,
        sx: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: '96vw',
          margin: 'auto',
        },
      },
    },
    MuiStack: {
      defaultProps: {
        alignItems: 'center',
        justifyContent: 'center',
        spacing: 1,
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        variant: 'standard',
      },
    },
    MuiToggleButtonGroup: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: ({ ownerState, _ }) => ({
          ...(ownerState.link === 'true' && {
            '&:hover': {
              cursor: 'pointer',
              color: palette.secondary.main,
            },
          }),
        }),
      },
    },
  },
};

const Theme = responsiveFontSizes(
  createTheme({
    ...constants,
    palette: {
      mode: 'light',
      ...palette,
    },
  })
);

export default Theme;
