import React from 'react';

import useGetApps from '../hooks/useGetApps';

import { SwitchTransition } from 'react-transition-group';
import { CircularProgress, Fade } from '@mui/material';

import AppList from '../components/AppList';

const Home = () => {
  const [loading, apps] = useGetApps();

  return (
    <>
      <img
        src="https://easy-outfitting.s3.ca-central-1.amazonaws.com/icon-text-green.png"
        alt="Easy Outfitting"
        style={{ maxHeight: '25vh' }}
      />
      <SwitchTransition>
        <Fade key={loading}>{loading ? <CircularProgress /> : <AppList apps={apps} />}</Fade>
      </SwitchTransition>
    </>
  );
};

export default Home;
