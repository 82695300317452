import { useEffect, useState } from 'react';

const useGetApps = () => {
  const [loading, setLoading] = useState(false);
  const [apps, setApps] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const response = await fetch(`${process.env.REACT_APP_API}/splash-list`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const data = await response.json();

        setApps(data);

        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    })();
  }, []);

  return [loading, apps];
};

export default useGetApps;
