import React from 'react';

import { Card, CardHeader, Avatar, Link } from '@mui/material';

const AppList = props => {
  const { logoUrl, name, url } = props;

  return (
    <Card>
      <CardHeader
        avatar={<Avatar src={logoUrl} alt={name} variant="square" sx={{ width: 75, height: 'auto' }} />}
        title={name}
        titleTypographyProps={{ variant: 'h5' }}
        subheader={<Link href={url}>Login to {name}</Link>}
      />
    </Card>
  );
};

export default AppList;
