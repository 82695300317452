import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';

import App from '../App';
import Theme from './index';

const Provider = () => {
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  );
};

export default Provider;
