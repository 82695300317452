import React from 'react';

import Stack from '@mui/material/Stack';

import Home from './containers/Home';

export default function App() {
  return (
    <Stack sx={{ minHeight: window.innerHeight }} justifyContent="start" py={4} px={2}>
      <Home />
    </Stack>
  );
}
