import React, { forwardRef } from 'react';

import { Grid, Stack, Typography } from '@mui/material';

import App from './App';

const AppList = forwardRef((props, transitionRef) => {
  const { apps, style } = props;

  return (
    <Stack ref={transitionRef} style={style}>
      {apps?.length > 0 ? (
        <>
          <Typography align="center" variant="h6" py={2}>
            Here is a list of our clients to help get you to the proper sign in page
          </Typography>
          <Grid container columnGap={2} rowGap={2}>
            {apps.map(app => (
              <Grid item key={app._id}>
                <App {...app} />
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <Typography color="error">We are experiencing Technical Difficulties</Typography>
      )}
    </Stack>
  );
});

export default AppList;
